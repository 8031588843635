import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import LatestNews from '../components/blocks/LatestNews'
import CurrentPost from '../components/CurrentPost'

const Post = ({ data, pageContext, location }) => (
  <Layout
    page={data.post}
    pageContext={pageContext}
    location={location}
    cta={data?.wp?.callToAction?.cta}
  >
    <Seo
      title={data.post.title}
      seo={data.post.seo}
      featuredImage={data?.post?.news?.image?.localFile?.publicURL}
    />
    <CurrentPost node={data.post} />
    <LatestNews
      title="News Archive"
      latestPosts={data.latestPosts}
      pageContext={data.pageContext}
    />
  </Layout>
)

export default Post

export const query = graphql`
  query post($id: String!) {
    latestPosts: allWpPost(
      sort: { order: DESC, fields: date }
      filter: { id: { ne: $id }, status: { eq: "publish" } }
      limit: 6
    ) {
      nodes {
        status
        title
        uri
        content
        news {
          description
          ingress
          image {
            altText
            localFile {
              childImageSharp {
                fluid(maxWidth: 1200, base64Width: 140, quality: 85) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    post: wpPost(id: { eq: $id }) {
      id
      title
      uri
      slug
      status
      uri
      content
      news {
        description
        ingress
        imageText
        columns
        image {
          altText
          localFile {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1200, base64Width: 140, quality: 85) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        readingTime
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          id
          mediaDetails {
            width
            height
          }
          localFile {
            publicURL
          }
        }
        breadcrumbs {
          text
          url
        }
        schema {
          articleType
          pageType
          raw
        }
      }
    }
    wp {
      callToAction {
        cta {
          btnShow
          btnAltText
          btnSvg {
            localFile {
              publicURL
            }
          }
          btnLink {
            target
            title
            url
          }
          btnFilter {
            ... on WpPage {
              databaseId
            }
          }
          form {
            title
            textBottom
            text
            image {
              altText
              localFile {
                childImageSharp {
                  fluid(
                    maxWidth: 1920
                    base64Width: 240
                    quality: 85
                    toFormat: JPG
                  ) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            imageMobile {
              altText
              localFile {
                childImageSharp {
                  fluid(
                    maxWidth: 800
                    base64Width: 60
                    quality: 85
                    toFormat: JPG
                  ) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
