import React from 'react'
import Img from 'gatsby-image'
import ShareButtons from './news/shareButtons'
import parse from 'html-react-parser'
const Component = (item, num) => (
  <div className={`section news`}>
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-10">
          <h3 className="sub-title">News</h3>
          <h1 className="title">{item.node.title}</h1>
        </div>
        {item?.node?.news?.ingress && (
          <div className="col-12 col-md-10 ingress">
            <p>{parse(item.node.news.ingress)}</p>
          </div>
        )}
        {item?.node?.news?.image?.localFile?.childImageSharp?.fluid && (
          <div className="col-12 news-image-wrap">
            <div className="news-image">
              <Img
                fluid={item.node.news.image.localFile.childImageSharp.fluid}
                className="no-padding-image"
                alt={item.node.news.image.altText}
              />
            </div>
            {item.node.news.imageText && (
              <span className="alt-text">{item.node.news.imageText}</span>
            )}
          </div>
        )}
        {item?.node?.content && (
          <div
            className={`col-12 content ${
              item.node.news.columns === '1'
                ? 'col-md-10 columns-1'
                : 'columns-2'
            }`}
          >
            {parse(item.node.content)}
          </div>
        )}
        <ShareButtons
          url={item.node.uri}
          description={item.node.news.description}
        />
      </div>
    </div>
  </div>
)

export default Component
